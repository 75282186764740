interface Company {
  id: string;
  name: string;
  role: "Administrator" | "Agent";
}

export type UserWebsiteRole = "Client" | "Driver" | "Administrator" | "CarOwner";

export interface Website {
  websiteGuid: string;
  websiteName: string;
  isDefault: boolean;
  roles: UserWebsiteRole[];
  companies: Company[];
  websiteWideLogo: string;
}

interface ApiEndpoints {
  cbsApi: RegionalEndpoints;
}

interface RegionalEndpoints {
  main: string;
  eu: string;
  apac: string;
  us: string;
}

export interface UserSettings {
  userId: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  token: string | null;
  refreshToken: string | null;
  websites: Website[];
  defaultWebsiteGuid: string | null;
  gKey: string | null;
  isBookingDotCom: boolean;
  isSuperAdmin: boolean;
  dateFormat: string;
  timeFormat: string;
  preferredRegion: "main" | "eu" | "apac" | "us";
  apis?: ApiEndpoints;
}

const getSettings = (): UserSettings => ({
  userId: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  token: null,
  refreshToken: null,
  websites: [],
  defaultWebsiteGuid: null,
  gKey: null,
  isBookingDotCom: false,
  isSuperAdmin: false,
  dateFormat: "ddd, DD MMM YYYY",
  timeFormat: "HH:mm (A)",
  preferredRegion: "main",
  apis: undefined
});

const initialState: UserSettings = getSettings();

type Action = {
  type: string;
  data?: any;
};

function reducer(state: UserSettings = initialState, action: Action): UserSettings {
  switch (action.type) {
    case "setUserInfo": {
      //console.log("setUserInfo", action.data);
      let defaultWebsiteGuid: string | null = null;
      if (action.data && action.data.websites) {
        const defaultWebsite = action.data.websites.find((el: Website) => el.isDefault);
        if (defaultWebsite) {
          defaultWebsiteGuid = defaultWebsite.websiteGuid;
        }
      }

      if (action.data && action.data.email === 'radu.diaconeasa@gmail.com') {
        action.data.isSuperAdmin = true;
      }

      var oldGKey = state.gKey;
      return {
        ...state,
        userId: action.data.guid,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        email: action.data.email,
        phone: action.data.phone,
        token: action.data.jwtToken || action.data.token,
        refreshToken: action.data.refreshJwtToken || action.data.refreshToken,
        websites: action.data.websites,
        defaultWebsiteGuid: defaultWebsiteGuid,
        gKey: action.data.gKey ? action.data.gKey : oldGKey,
        isBookingDotCom: action.data.isBookingDotCom,
        isSuperAdmin: action.data.isSuperAdmin,
        dateFormat: action.data.dateFormat,
        timeFormat: action.data.timeFormat,
        preferredRegion: action.data.preferredRegion,
        apis: action.data.apis
      };
    }
    case "setToken": {
      if (action.data) {
        return { ...state, token: action.data };
      }
      // Clean user state at logout
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;
