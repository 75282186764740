import { UserSettings, UserWebsiteRole, Website } from "../store/reducers/userStore";
import { GoldsainteWebsiteGuid, TravelThruWebsiteGuid } from "./constants";

export const CarOwnerRole = "CarOwner";

export function isAdministrator(userState: UserSettings): boolean {
    const selectedWebsite = userState.websites ? userState.websites.find(w => w.isDefault) : null;
    if (!selectedWebsite) return false;
    return hasRole(selectedWebsite, "Administrator");
}

export interface DomainInfo {
    domain: "Goldsainte" | "TravelThru" | "Default",
    websiteGuid: string | null,
    selectedWebsite: Website | null,
    selectedWebsiteName: string | null,
    isGoldsainte: boolean,
    isTravelThru: boolean
}

export function getDomainInfo(userInfo: UserSettings | null): DomainInfo {
    var result = {
        domain: "Default",
        websiteGuid: null,
        selectedWebsite: null,
        selectedWebsiteName: null,
        isGoldsainte: false,
        isTravelThru: false,
        isProduction: (process.env?.VERCEL_ENV ? process.env?.VERCEL_ENV : "development") === "production"
    } as DomainInfo;

    if (!result.isGoldsainte) {
        result.isGoldsainte = window.location.hostname.indexOf("goldsainte") > -1;
        if (result.isGoldsainte) {
            result.domain = "Goldsainte";
            result.websiteGuid = GoldsainteWebsiteGuid;
        }
    }

    if (!result.isTravelThru) {
        result.isTravelThru = window.location.hostname.indexOf("travelthru") > -1 || window.location.hostname.indexOf("localhost") > -1;
        if (result.isTravelThru) {
            result.domain = "TravelThru";
            result.websiteGuid = TravelThruWebsiteGuid;
        }
    }

    if (!userInfo) return result;

    result.selectedWebsite = userInfo.websites ? userInfo.websites.find(w => w.isDefault) ?? null : null;
    result.selectedWebsiteName = userInfo.websites ? userInfo.websites.find(w => w.isDefault)?.websiteName ?? null : null;

    if (!result.isGoldsainte) {
        result.isGoldsainte = (result.selectedWebsite && result.selectedWebsite.websiteGuid === GoldsainteWebsiteGuid) ?? false;
        if (result.isGoldsainte) result.domain = "Goldsainte";
    }

    if (!result.isTravelThru) {
        result.isTravelThru = (result.selectedWebsite && result.selectedWebsite.websiteGuid === TravelThruWebsiteGuid) ?? false;
        if (result.isTravelThru) result.domain = "TravelThru";
    }

    return result;
}

export function isCarOwner(userState: UserSettings): boolean {
    const selectedWebsite = userState.websites ? userState.websites.find(w => w.isDefault) : null;
    const isGoldsainte = selectedWebsite && selectedWebsite.websiteGuid == GoldsainteWebsiteGuid;
    const isCarOwner = selectedWebsite && isGoldsainte && userState.websites.some(w => w.roles.includes(CarOwnerRole));
    return isCarOwner ?? false;
}

export function hasRole(websiteWithRoles: Website, roleName: UserWebsiteRole): boolean {
    if (!websiteWithRoles?.roles) return false;
    return websiteWithRoles.roles.find(r => r === roleName) != null;
}

//   export function hasAnyRole(websiteWithRoles, roleNames) {
//     if(!websiteWithRoles?.roles) return false;
//     for (const roleName of roleNames) {
//       const hasRole = websiteWithRoles.roles.find(r => r === roleName) != null;
//       if (hasRole) return true;
//     }
//     return false;
//   }